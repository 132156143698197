var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "details"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("资产明细")]), _c('table', [_c('tr', [_c('td', [_vm._v("#")]), _c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("数量")]), _c('td', [_vm._v("单位")]), _c('td', {
          staticClass: "required"
        }, [_c('i', [_vm._v("*")]), _vm._v("此次入库数量")])]), _vm._l(_vm.detailList, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.productName))]), _c('td', [_vm._v(_vm._s(item.standard))]), _c('td', [_vm._v(_vm._s(item.inNumTotal))]), _c('td', [_vm._v(_vm._s(item.unit))]), _c('td', {
            staticClass: "input"
          }, [_c('van-stepper', {
            attrs: {
              "integer": "",
              "min": 0,
              "max": item.inNumTotal,
              "button-size": "20px"
            },
            model: {
              value: item.inNum,
              callback: function ($$v) {
                _vm.$set(item, "inNum", $$v);
              },
              expression: "item.inNum"
            }
          })], 1)]);
        })], 2)]), _c('van-cell-group', {
          staticClass: "cell-group form",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          staticClass: "picker",
          attrs: {
            "title": "选择该批资产的进入仓库",
            "required": ""
          },
          on: {
            "click": _vm.onInSpaceListShow
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.spaceName === ''
                }
              }, [_vm._v(_vm._s(_vm.spaceName || '请选择') + " "), _c('van-icon', {
                attrs: {
                  "name": "arrow"
                }
              })], 1)];
            },
            proxy: true
          }])
        }), _vm.sourceType == 1 ? _c('van-cell', {
          staticClass: "picker",
          attrs: {
            "title": "此次入库方式",
            "required": ""
          },
          on: {
            "click": function ($event) {
              _vm.stockMethodShow = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.stockMethodName === ''
                }
              }, [_vm._v(_vm._s(_vm.stockMethodName || '请选择') + " "), _c('van-icon', {
                attrs: {
                  "name": "arrow"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 995357534)
        }) : _vm._e(), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "label": "备注",
            "placeholder": "请输入",
            "type": "textarea",
            "rows": "3",
            "autosize": "",
            "show-word-limit": "",
            "maxlength": "200"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "入库人签字",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "signPanel"
              }, [_c('vue-esign', {
                ref: "esign",
                attrs: {
                  "bgColor": "#F5F5F5",
                  "isClearBgColor": false,
                  "format": "image/jpeg"
                }
              }), _c('div', {
                staticClass: "clear",
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.onClearSign.apply(null, arguments);
                  }
                }
              }, [_vm._v("清空画布")])], 1)];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "上传照片",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "uploadPanel"
              }, [_c('van-uploader', {
                attrs: {
                  "after-read": _vm.afterFileRead
                },
                model: {
                  value: _vm.stockInImgList,
                  callback: function ($$v) {
                    _vm.stockInImgList = $$v;
                  },
                  expression: "stockInImgList"
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v("提交")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.inSpaceListShow,
      callback: function ($$v) {
        _vm.inSpaceListShow = $$v;
      },
      expression: "inSpaceListShow"
    }
  }, [_c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "value-key": "spaceName",
      "columns": _vm.inSpaceList
    },
    on: {
      "confirm": _vm.onInSpaceListSelect,
      "cancel": function ($event) {
        _vm.inSpaceListShow = false;
      }
    }
  })], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.stockMethodShow,
      callback: function ($$v) {
        _vm.stockMethodShow = $$v;
      },
      expression: "stockMethodShow"
    }
  }, [_c('van-picker', {
    attrs: {
      "show-toolbar": "",
      "columns": _vm.stockMethodList
    },
    on: {
      "confirm": _vm.onSelectStockMethod,
      "cancel": function ($event) {
        _vm.stockMethodShow = false;
      }
    }
  })], 1), _c('van-dialog', {
    attrs: {
      "title": "注意",
      "confirm-button-text": "我已知晓"
    },
    on: {
      "confirm": _vm.onGdListConfirm
    },
    model: {
      value: _vm.gdListShow,
      callback: function ($$v) {
        _vm.gdListShow = $$v;
      },
      expression: "gdListShow"
    }
  }, [_c('div', {
    staticClass: "gdList"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("此次盘点资产中，有以下固定资产被赋予资产编号")]), _c('table', [_c('thead', [_c('tr', [_c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("资产编号")])])]), _c('tbody', _vm._l(_vm.gdList, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.productName || '-'))]), _c('td', [_vm._v(_vm._s(item.standard || '-'))]), _c('td', [_vm._v(_vm._s(item.productNo || '-'))])]);
  }), 0)])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };