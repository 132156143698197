import common from '@mixins/common';
import vueEsign from 'vue-esign';
import * as assetsApi from '@api/assets';
import { mapGetters } from 'vuex';
import imageCompression from 'browser-image-compression';
export default {
  name: 'AssetsInDetail',
  mixins: [common],
  components: {
    vueEsign
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      sourceId: '',
      sourceTitle: '',
      sourceType: '',
      detailList: [],
      spaceFullCode: '',
      spaceName: '',
      stockMethod: '',
      projectId: '',
      ourUser: '',
      remark: '',
      stockUserSignUrl: '',
      stockInImgList: [],
      inSpaceListShow: false,
      inSpaceList: [],
      stockMethodShow: false,
      stockMethodName: '',
      stockMethodList: [{
        value: 1,
        text: '部分入库'
      }, {
        value: 2,
        text: '全部入库'
      }],
      gdListShow: false,
      gdList: []
    };
  },
  created() {
    this.sourceId = this.$route.query.sourceId;
    this.sourceTitle = this.$route.query.sourceTitle;
    this.sourceType = this.$route.query.sourceType;
    this.projectId = this.projectInfo.dcProjectId;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getStockInDetailInit({
        sourceId: this.sourceId,
        sourceType: this.sourceType
      }).then(res => {
        let item = res.data.data;
        if (item.detailList) {
          for (let detail of item.detailList) {
            detail.inNum = 0;
          }
          this.detailList = item.detailList || [];
        }
      });
    },
    onInSpaceListShow() {
      let {
        dcProjectId
      } = this.projectInfo;
      assetsApi.getSpaceListByRole(dcProjectId).then(res => {
        if (res.data.data.result) {
          this.inSpaceList = res.data.data.result;
        }
      });
      this.inSpaceListShow = true;
    },
    onInSpaceListSelect(item) {
      this.spaceFullCode = item.spaceFullCode;
      this.spaceName = item.spaceFullName;
      this.inSpaceListShow = false;
    },
    onSelectStockMethod(item) {
      this.stockMethod = item.value;
      this.stockMethodName = item.text;
      this.stockMethodShow = false;
    },
    onClearSign() {
      this.$refs.esign.reset();
      this.stockUserSignUrl = '';
    },
    afterFileRead(item) {
      let up = this.$toast.loading({
        forbidClick: true,
        duration: 0
      });
      imageCompression(item.file, {
        maxSizeMB: 0.2,
        initialQuality: 0.7
      }).then(file => {
        assetsApi.fileUpload(item).then(res => {
          this.$toast.clear();
          const cb = res.data;
          if (cb.code === 200) {
            item.fileType = 1;
            item.url = cb.data.url;
            item.fileUrl = cb.data.url;
            item.status = 'success';
            item.message = '上传成功';
          } else {
            item.status = 'failed';
            item.message = '上传失败';
          }
        }).catch(e => {
          this.$toast.clear();
          item.status = 'failed';
          item.message = '上传失败';
        });
      }).catch(() => {
        this.$toast.clear();
        item.status = 'failed';
        item.message = '上传失败';
      });
    },
    async onSubmit() {
      if (!this.spaceFullCode) {
        this.$toast('请选择进入仓库');
        return;
      }
      if (this.sourceType == 1 && !this.stockMethod) {
        this.$toast('请选择入库方式');
        return;
      }
      if (!this.stockUserSignUrl) {
        let base64Str;
        try {
          base64Str = await this.$refs.esign.generate();
          if (base64Str) {
            base64Str = base64Str.substring(23);
          }
        } catch (e) {
          this.$toast('请签名');
          return;
        }
        let res = await assetsApi.fileUploadBase64(base64Str);
        this.stockUserSignUrl = res.data.data.src;
      }
      if (this.stockInImgList.filter(i => i.status == 'success').length == 0) {
        this.$toast('请上传图片');
        return;
      }
      this.$dialog.confirm({
        message: '是否确认提交？'
      }).then(() => {
        assetsApi.inStock({
          sourceType: this.sourceType,
          sourceId: this.sourceId,
          sourceTitle: this.sourceTitle,
          spaceFullCode: this.spaceFullCode,
          spaceName: this.spaceName,
          projectId: this.projectId,
          stockMethod: this.stockMethod,
          stockUserSignUrl: this.stockUserSignUrl,
          stockInImgList: this.stockInImgList.map(i => {
            return {
              fileUrl: i.fileUrl
            };
          }),
          ourUser: '',
          // 采购入库不用传outKeeper
          remark: this.remark,
          detailList: this.detailList
        }).then(res => {
          if (res.data.data && res.data.data.guDingStockList && res.data.data.guDingStockList.length) {
            this.gdList = res.data.data.guDingStockList;
            this.gdListShow = true;
          } else {
            this.$router.replace({
              name: 'AssetsInSuccess',
              query: {
                sourceType: this.sourceType
              }
            });
          }
        });
      }).catch(() => {
        // on cancel
      });
    },
    onGdListConfirm() {
      this.gdListShow = false;
      this.$router.replace({
        name: 'AssetsInSuccess',
        query: {
          sourceType: this.sourceType
        }
      });
    }
  }
};